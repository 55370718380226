// AchievementsScreen.jsx
import React, { useEffect, useState } from 'react';
import {
  Card,
  Progress,
  Row,
  Col,
  Typography,
  message,
  Statistic,
  Space,
} from 'antd';
import {
  ShoppingCartOutlined,
  DollarOutlined,
  LineChartOutlined,
  TrophyOutlined,
  GiftOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { LevelProvider } from './LevelProvider';

const { Title, Text } = Typography;

// Regras de nível
const levelRules = [
  // Níveis 1-10: Aumento de 15%
  { level: 1, salesRequired: 0,    totalProfitRequired: 0,            revenueRequired: 0 },
  { level: 2, salesRequired: 20,   totalProfitRequired: 104880,       revenueRequired: 2208000 },
  { level: 3, salesRequired: 40,   totalProfitRequired: 524400,       revenueRequired: 5520000 },
  { level: 4, salesRequired: 60,   totalProfitRequired: 1573200,      revenueRequired: 11040000 },
  { level: 5, salesRequired: 90,   totalProfitRequired: 3158400,      revenueRequired: 16560000 },
  { level: 6, salesRequired: 110,  totalProfitRequired: 5244000,      revenueRequired: 27600000 },
  { level: 7,  salesRequired: 104, totalProfitRequired: 5677920,  revenueRequired: 30912000 },
  { level: 8,  salesRequired: 136, totalProfitRequired: 9096192,  revenueRequired: 39744000 },
  { level: 9,  salesRequired: 160, totalProfitRequired: 13678848, revenueRequired: 52992000 },
  { level: 10, salesRequired: 184, totalProfitRequired: 15175296, revenueRequired: 70656000 },
  // Níveis 11-20: Aumento de 25% aplicado após redução
  
    { level: 11, salesRequired: 240, totalProfitRequired: 23256000, revenueRequired: 80920000 },
    { level: 12, salesRequired: 290, totalProfitRequired: 27907200, revenueRequired: 117504000 },
    { level: 13, salesRequired: 340, totalProfitRequired: 27132000, revenueRequired: 122400000 },
    { level: 14, salesRequired: 390, totalProfitRequired: 31008000, revenueRequired: 146880000 },
    { level: 15, salesRequired: 440, totalProfitRequired: 38760000, revenueRequired: 179520000 },
    { level: 16, salesRequired: 660, totalProfitRequired: 63954000, revenueRequired: 291720000 },
    { level: 17, salesRequired: 730, totalProfitRequired: 74613000, revenueRequired: 336600000 },
    { level: 18, salesRequired: 798, totalProfitRequired: 85272000, revenueRequired: 392700000 },
    { level: 19, salesRequired: 867, totalProfitRequired: 95931000, revenueRequired: 448800000 },
    { level: 20, salesRequired: 935, totalProfitRequired: 106590000, revenueRequired: 504900000 },
  
  
  // Níveis 21-30: Continuação com aumento de 10% e ajuste progressivo
  { level: 21, salesRequired: 1029, totalProfitRequired: 137940000,   revenueRequired: 653400000 },
  { level: 22, salesRequired: 1131, totalProfitRequired: 152130000,   revenueRequired: 718740000 },
  { level: 23, salesRequired: 1244, totalProfitRequired: 166815000,   revenueRequired: 790020000 },
  { level: 24, salesRequired: 1368, totalProfitRequired: 182506500,   revenueRequired: 867900000 },
  { level: 25, salesRequired: 1505, totalProfitRequired: 199656600,   revenueRequired: 954690000 },
  { level: 26, salesRequired: 1655, totalProfitRequired: 218521760,   revenueRequired: 1049769000 },
  { level: 27, salesRequired: 1810, totalProfitRequired: 239301136,   revenueRequired: 1155363000 },
  { level: 28, salesRequired: 1971, totalProfitRequired: 261701136,   revenueRequired: 1270899300 },
  { level: 29, salesRequired: 2148, totalProfitRequired: 287332275,   revenueRequired: 1397989230 },
  { level: 30, salesRequired: 2363, totalProfitRequired: 314832375,   revenueRequired: 1537788153 },
  // Níveis 31-40: Aumento de 10% com ajuste progressivo
  
];

// Recompensas monetárias
const monetaryRewards = {
  1: 10000,
  2: 50000,
  3: 80000,
  4: 100000,
  5: 120000,
  6: 140000,
  7: 160000,
  8: 180000,
  9: 200000,
  10: 300000,
  11: 350000,   // 700.000 / 2
  12: 450000,   // 900.000 / 2
  13: 550000,   // 1.100.000 / 2
  14: 650000,   // 1.300.000 / 2
  15: 750000,   // 1.500.000 / 2
  16: 850000,   // 1.700.000 / 2
  17: 950000,   // 1.900.000 / 2
  18: 1050000,  // 2.100.000 / 2
  19: 1150000,  // 2.300.000 / 2
  20: 1250000,  // 2.500.000 / 2
  21: 1500000,  // 3.000.000 / 2
  22: 1750000,  // 3.500.000 / 2
  23: 2000000,  // 4.000.000 / 2
  24: 2250000,  // 4.500.000 / 2
  25: 2500000,  // 5.000.000 / 2
  26: 2750000,  // 5.500.000 / 2
  27: 3000000,  // 6.000.000 / 2
  28: 3250000,  // 6.500.000 / 2
  29: 3500000,  // 7.000.000 / 2
  30: 3750000,  // 7.500.000 / 2
  31: 4000000,  // 8.000.000 / 2
  32: 4250000,  // 8.500.000 / 2
  33: 4500000,  // 9.000.000 / 2
  34: 4750000,  // 9.500.000 / 2
  35: 5000000,  // 10.000.000 / 2
  36: 5250000,  // 10.500.000 / 2
  37: 5500000,  // 11.000.000 / 2
  38: 5750000,  // 11.500.000 / 2
  39: 6000000,  // 12.000.000 / 2
  40: 6250000,  // 12.500.000 / 2
  41: 6500000,  // 13.000.000 / 2
  42: 6750000,  // 13.500.000 / 2
  43: 7000000,  // 14.000.000 / 2
  44: 7250000,  // 14.500.000 / 2
  45: 7500000,  // 15.000.000 / 2
  46: 7750000,  // 15.500.000 / 2
  47: 8000000,  // 16.000.000 / 2
  48: 8250000,  // 16.500.000 / 2
  49: 8500000,  // 17.000.000 / 2
  50: 8750000,  // 17.500.000 / 2
  
};

const AchievementsScreen = () => {
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchPlayerData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const playerDoc = await getDoc(doc(firestore, 'players', user.uid));
          if (playerDoc.exists()) {
            setPlayerData(playerDoc.data());
            setLoading(false);
          } else {
            message.error('Não foi possível carregar os dados do jogador.');
            setLoading(false);
          }
        } catch (error) {
          message.error('Erro ao buscar dados do jogador.');
          setLoading(false);
        }
      } else {
        navigate('/auth');
      }
    };
    fetchPlayerData();
  }, [auth, navigate]);

  // Função para obter a percentagem de progresso
  const getProgressForMetric = (current, required) => {
    if (required === 0) return 100; // Evita divisão por zero
    return Math.min(100, Math.ceil((current / required) * 100));
  };

  // Função para renderizar cada métrica
  const renderMetricCard = (icon, title, current, required, suffix) => (
    <Card
      style={{
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        marginBottom: '20px',
        backgroundColor: '#ffffff',
      }}
    >
      <Space direction="horizontal" size="middle">
        {icon}
        <div>
          <Text strong>{title}</Text>
          <Progress
            percent={getProgressForMetric(current, required)}
            status="active"
            strokeColor="#202020"
            showInfo={false}
          />
          <Statistic
            value={Math.ceil(current || 0)} // Arredonda para cima
            suffix={` / ${required}${suffix}`}
            valueStyle={{ fontSize: '16px' }}
          />
        </div>
      </Space>
    </Card>
  );

  // Função para renderizar todas as métricas
  const renderAllMetrics = () => {
    if (!playerData || !playerData.level) return null;

    const currentLevel = playerData.level;
    const nextLevelRule = levelRules.find((rule) => rule.level === currentLevel + 1);

    if (!nextLevelRule) {
      return (
        <Card
          style={{
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            marginBottom: '30px',
            padding: '20px',
            backgroundColor: '#ffffff',
            textAlign: 'center',
          }}
        >
          <Title level={4} style={{ color: '#080808' }}>
            Parabéns! Você atingiu o nível máximo.
          </Title>
        </Card>
      );
    }

    const metrics = [
      {
        icon: <ShoppingCartOutlined style={{ fontSize: '24px', color: '#080808' }} />,
        title: 'Total de Vendas',
        current: playerData.metrics.totalSales || 0,
        required: nextLevelRule.salesRequired,
        suffix: ' vendas',
      },
      {
        icon: <DollarOutlined style={{ fontSize: '24px', color: '#080808' }} />,
        title: 'Receita Total',
        current: playerData.metrics.totalRevenue || 0,
        required: nextLevelRule.revenueRequired,
        suffix: ' R$',
      },
      {
        icon: <LineChartOutlined style={{ fontSize: '24px', color: '#080808' }} />,
        title: 'Lucro Total',
        current: playerData.metrics.totalProfit || 0,
        required: nextLevelRule.totalProfitRequired,
        suffix: ' R$',
      },
    ];

    return (
      <Card
        style={{
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          marginBottom: '30px',
          padding: '20px',
          backgroundColor: '#ffffff',
        }}
      >
        <Title level={4} style={{ marginBottom: '20px', color: '#b5b5b5' }}>
          Progresso para o Nível {nextLevelRule.level}
        </Title>
        <Row gutter={[16, 16]}>
          {metrics.map((metric, index) => (
            <Col xs={24} sm={12} md={8} key={index}>
              {renderMetricCard(
                metric.icon,
                metric.title,
                metric.current,
                metric.required,
                metric.suffix
              )}
            </Col>
          ))}
        </Row>
      </Card>
    );
  };

  // Função para verificar e atualizar o nível do jogador e conceder recompensas
  const checkAndUpdateLevel = async () => {
    if (!playerData || !playerData.level) return;

    const currentLevel = playerData.level;
    const nextLevelRule = levelRules.find((rule) => rule.level === currentLevel + 1);

    if (!nextLevelRule) return; // Jogador está no nível máximo

    const metrics = playerData.metrics || {};

    const salesProgress = metrics.totalSales >= nextLevelRule.salesRequired;
    const profitProgress = metrics.totalProfit >= nextLevelRule.totalProfitRequired;
    const revenueProgress = metrics.totalRevenue >= nextLevelRule.revenueRequired;

    // Verifica se o jogador atingiu todos os requisitos
    if (salesProgress && profitProgress && revenueProgress) {
      try {
        // Atualiza o nível do jogador no Firestore
        const user = auth.currentUser;
        if (!user) {
          message.error('Usuário não autenticado');
          return;
        }
        const playerRef = doc(firestore, 'players', user.uid);

        // Adiciona a recompensa monetária ao saldo do jogador, arredondando para cima
        const rewardAmount = monetaryRewards[currentLevel + 1] || 0;
        const newBalance = Math.ceil((playerData.balance || 0) + rewardAmount);

        await setDoc(
          playerRef,
          {
            level: currentLevel + 1,
            balance: newBalance, // Saldo arredondado para cima
          },
          { merge: true }
        );

        // Atualiza o estado local para refletir o novo nível e saldo
        setPlayerData((prevData) => ({
          ...prevData,
          level: currentLevel + 1,
          balance: newBalance,
        }));

        message.success(
          `Parabéns! Você atingiu o nível ${currentLevel + 1} e recebeu R$${rewardAmount
            .toLocaleString('pt-BR')
            .replace(',', '.')}`
        );
      } catch (error) {
        message.error('Erro ao atualizar o nível do jogador');
        console.error('Erro ao atualizar o nível do jogador:', error);
      }
    }
  };

  useEffect(() => {
    if (playerData) {
      checkAndUpdateLevel(); // Verifica e atualiza o nível
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData]);

  // Função para renderizar as recompensas
  const renderRewards = () => {
    if (!playerData || !playerData.level) return null;

    const currentLevel = playerData.level;
    const rewards = monetaryRewards[currentLevel + 1];

    if (!rewards) return null; // Sem prêmios para este nível

    return (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Title level={4}>Recompensa do Próximo Nível</Title>
        </Col>
        <Col xs={24}>
          <Card
            style={{
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              marginBottom: '20px',
              backgroundColor: '#ffffff',
            }}
          >
            <Space direction="horizontal" size="middle">
              <GiftOutlined style={{ fontSize: '24px', color: '#080808' }} />
              <div>
                <Text>
                  <strong>Recompensa:</strong> R$
                  {Math.ceil(rewards).toLocaleString('pt-BR')}
                </Text>
              </div>
            </Space>
          </Card>
        </Col>
      </Row>
    );
  };

  // Função para renderizar o conteúdo principal
  const renderContent = () => {
    if (loading) {
      return <Text>Carregando dados...</Text>;
    }

    if (!playerData) {
      return <Text>Dados do jogador não disponíveis.</Text>;
    }

    return (
      <>
        {renderAllMetrics()}
        {renderRewards()}
      </>
    );
  };

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '1920px',
        margin: '0 auto',
        backgroundColor: '#f0f2f5',
        minHeight: '100vh',
      }}
    >
      <LevelProvider playerData={playerData} setPlayerData={setPlayerData} />

      {renderContent()}
    </div>
  );
};

export default AchievementsScreen;
