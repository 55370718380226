import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Button, Modal, message, Spin } from 'antd';
import {
  DollarOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  CrownOutlined,
  ShoppingFilled,
  PayCircleOutlined,
} from '@ant-design/icons';
import { doc, getDoc, updateDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { firestore } from './firebaseConfig'; // Certifique-se de que este caminho está correto

const { Text } = Card;

const OffersSection = () => {
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const auth = getAuth();

  /**
   * Função para buscar dados do jogador
   */
  const fetchPlayerData = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const playerDocRef = doc(firestore, 'players', user.uid);
        const playerDocSnap = await getDoc(playerDocRef);

        if (playerDocSnap.exists()) {
          const data = playerDocSnap.data();

          // Verifica se há um buff ativo
          if (data.buffActive && data.lastbufftime && data.selectedOffer) {
            const now = new Date();
            const buffStartTime = data.lastbufftime.toDate();
            const buffDuration = data.selectedOffer.duration; // em minutos
            const buffEndTime = new Date(buffStartTime.getTime() + buffDuration * 60000);

            if (now >= buffEndTime) {
              // Buff expirou, resetar os valores
              await updateDoc(playerDocRef, {
                profit: data.originalProfit || data.profit / data.selectedOffer.profitMultiplier,
                buffActive: false,
                selectedOffer: null,
                lastbufftime: null,
                originalProfit: null,
              });

              setPlayerData({
                ...data,
                profit: data.originalProfit || data.profit / data.selectedOffer.profitMultiplier,
                buffActive: false,
                selectedOffer: null,
                lastbufftime: null,
                originalProfit: null,
              });

              message.info('O buff expirou e o lucro foi restaurado ao normal.');
            } else {
              // Buff ainda está ativo
              setPlayerData(data);
            }
          } else {
            // Sem buff ativo
            setPlayerData(data);
          }
        } else {
          message.error('Documento do jogador não encontrado.');
        }
      } else {
        message.error('Usuário não autenticado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
      message.error('Erro ao buscar dados do jogador.');
    } finally {
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    fetchPlayerData();
  }, [fetchPlayerData]);

  /**
   * Função para abrir o modal de confirmação
   */
  const showPurchaseConfirmation = (offer) => {
    if (playerData.buffActive) {
      message.error('Você já tem um buff ativo. Aguarde ele expirar antes de comprar outro.');
      return;
    }
    setSelectedOffer(offer);
    setIsModalVisible(true);
  };

  /**
   * Função para confirmar a compra do buff
   */
  const handleConfirmPurchase = async () => {
    if (playerData && selectedOffer) {
      const { balance } = playerData;
      const { price, profitMultiplier, duration } = selectedOffer;

      if (balance >= price) {
        const newBalance = balance - price;

        try {
          const playerDocRef = doc(firestore, 'players', auth.currentUser.uid);

          // Salvar o lucro original antes de aplicar o buff
          const originalProfit = playerData.originalProfit || playerData.profit;

          // Atualiza o documento do jogador com o novo balance, buff, e define buffActive como true
          await updateDoc(playerDocRef, {
            balance: newBalance,
            profit: originalProfit * profitMultiplier,
            buffActive: true, // Define que o buff está ativo
            selectedOffer: selectedOffer, // Salva a oferta selecionada
            lastbufftime: serverTimestamp(), // Registra o tempo em que o buff foi comprado
            originalProfit: originalProfit, // Armazena o lucro original
          });

          message.success('Buff adquirido com sucesso!');
          setPlayerData({
            ...playerData,
            balance: newBalance,
            profit: originalProfit * profitMultiplier,
            buffActive: true,
            selectedOffer: selectedOffer,
            lastbufftime: Timestamp.now(),
            originalProfit: originalProfit,
          });

          setIsModalVisible(false);
        } catch (error) {
          console.error('Erro ao atualizar o jogador:', error);
          message.error('Erro ao realizar a compra.');
        }
      } else {
        message.error('Saldo insuficiente.');
      }
    }

    setIsModalVisible(false);
  };

  /**
   * Definir ofertas
   */
  const offers = [
    {
      title: 'Lucro Duplo por 1 hora',
      description: 'Receba 2x mais lucro por 1 hora',
      price: 2000000,
      profitMultiplier: 2,
      duration: 60, // 1 hora em minutos
    },
    {
      title: 'Lucro Duplo por 5 horas',
      description: 'Receba 2x mais lucro por 5 horas',
      price: 6000000,
      profitMultiplier: 2,
      duration: 300, // 5 horas em minutos
    },
    {
      title: 'Lucro Triplo por 5 horas',
      description: 'Receba 3x mais lucro por 5 horas',
      price: 12000000,
      profitMultiplier: 3,
      duration: 300, // 5 horas em minutos
    },
  ];

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin tip="Carregando ofertas..." size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={[16, 16]}>
        {offers.map((offer, index) => (
          <Col key={index} xs={24} sm={12} md={8}>
            <Card
              title={offer.title}
              bordered={false}
              style={{
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
            >
              <p>{offer.description}</p>
              <p>
                <strong>Preço:</strong> R$ {offer.price.toLocaleString('pt-BR')}
              </p>
              <Button
                type="primary"
                style={{
                  width: '100%',
                  backgroundColor: '#080808',
                  borderColor: '#080808',
                }}
                onClick={() => showPurchaseConfirmation(offer)}
              >
                Comprar
              </Button>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal de Confirmação */}
      <Modal
        title="Confirmar Compra"
        visible={isModalVisible}
        onOk={handleConfirmPurchase}
        onCancel={() => setIsModalVisible(false)}
        okText="Confirmar"
        cancelText="Cancelar"
        confirmLoading={isProcessing}
      >
        {selectedOffer && (
          <div>
            <p>
              Você deseja comprar o buff <strong>{selectedOffer.title}</strong> por{' '}
              <strong>R$ {selectedOffer.price.toLocaleString('pt-BR')}</strong>?
            </p>
            <p>
              Com esse buff, seu lucro será multiplicado por <strong>{selectedOffer.profitMultiplier}x</strong> por{' '}
              <strong>{selectedOffer.duration / 60} hora(s)</strong>.
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default OffersSection;
