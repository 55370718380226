import React, { useState, useEffect } from 'react';
import {
  Layout,
  Form,
  Input,
  Button,
  message,
  Typography,
  Row,
  Col,
  Grid,
} from 'antd';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { firestore } from './firebaseConfig';
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid;

const AuthScreen = () => {
  const [isSignup, setIsSignup] = useState(false); // Estado para alternar entre login e signup
  const [loading, setLoading] = useState(false); // Estado para gerenciar o carregamento do botão
  const [form] = Form.useForm(); // Instância do formulário do Ant Design
  const navigate = useNavigate(); // Hook para navegação
  const auth = getAuth(); // Inicialização da autenticação Firebase

  // Hook para capturar o tamanho da tela (responsividade)
  const screens = useBreakpoint();

  // Resetar os campos do formulário ao alternar entre login e signup
  useEffect(() => {
    form.resetFields();
  }, [isSignup, form]);

  /**
   * Função para verificar se o nome da loja já existe no Firestore
   * @param {string} name - Nome da loja a ser verificado
   * @returns {Promise<boolean>} - Retorna true se único, false caso contrário
   */
  const checkStoreNameUnique = async (name) => {
    try {
      const storeNameDoc = await getDoc(doc(firestore, 'storeNames', name));
      return !storeNameDoc.exists();
    } catch (error) {
      console.error('Erro ao verificar a unicidade do nome da loja:', error);
      throw error;
    }
  };

  /**
   * Função para criar o documento do jogador e registrar o nome da loja no Firestore
   * @param {string} uid - UID do usuário
   * @param {string} storeName - Nome da loja
   * @param {string} playerName - Nome do jogador
   * @param {string} whatsapp - Número de WhatsApp do jogador
   */
  const createPlayerDocument = async (uid, storeName, playerName, whatsapp) => {
    try {
      // Data atual
      const createdAt = new Date();

      // Cria o documento do jogador no Firestore com nível inicial
      const playerData = {
        balance: 1000000, // Saldo inicial
        gold: 0,
        buff: 0,
        debt: 0,
        originalProfit: null,
        selectedOffer: null,
        lastbufftime: null,
        loanTakenAt: null,
        repaymentDeadline: null,
        isca: false,
        metrics: {
          averageSalesValue: 0,
          carnumber: 0,
          showroomValue: 0,
          totalProfit: 0,
          totalRevenue: 0,
          totalSales: 0,
          carrosComprados: 0,
        },
        name: storeName, // Nome da loja
        nameplayer: playerName, // Nome do jogador
        email: auth.currentUser.email.toLowerCase(),
        level: 1,
        levelsProcessed: [1], // Níveis processados (caso de evolução)
        slots: 2, // Número inicial de vagas no showroom
        group: 70,
        profit: 2, // Lucro padrão (%)
        sub: 0, // 0 padrão - 1 million - 2 billion
        whatsapp: whatsapp, // Número de WhatsApp
        atendido: false,

        // Campos para a data de criação da conta
        createdAt: createdAt.toISOString(), // Data de criação da conta
      };

      // Salva o documento do jogador na coleção 'players' com o UID como ID
      await setDoc(doc(firestore, 'players', uid), playerData);

      // Adiciona o nome da loja na coleção 'storeNames' com UID
      await setDoc(doc(firestore, 'storeNames', storeName), { uid });

      // Cópia dos clientes do nível 1 após a criação do jogador
      const clientsCollectionRef = collection(firestore, 'clientglobal');
      const clientsQuery = query(clientsCollectionRef, where('nivel', '==', 1));
      const clientsSnapshot = await getDocs(clientsQuery);

      if (!clientsSnapshot.empty) {
        const clientsList = clientsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const activeClientsRef = collection(
          firestore,
          'players',
          uid,
          'activeclients'
        );
        const batch = writeBatch(firestore);

        clientsList.forEach((client) => {
          const clientRef = doc(activeClientsRef, client.id);
          batch.set(clientRef, client);
        });

        await batch.commit();
        console.log(`Clientes do nível 1 copiados para 'activeclients'.`);
      }
    } catch (error) {
      console.error(
        'Erro ao criar o jogador e copiar clientes do nível 1:',
        error
      );
      message.error('Erro ao criar o jogador.');
      throw error; // Propaga o erro para ser capturado na função de cadastro
    }
  };

  /**
   * Função para lidar com a autenticação (login ou signup)
   * @param {Object} values - Valores do formulário
   */
  const handleAuth = async (values) => {
    let { email, password, storeName, playerName, whatsapp } = values;

    // Validação básica para garantir que todos os campos necessários estejam preenchidos
    if (
      !email ||
      !password ||
      (isSignup && (!storeName || !playerName || !whatsapp))
    ) {
      message.error('Por favor, preencha todos os campos.');
      return;
    }

    // Normaliza o email para letras minúsculas
    email = email.toLowerCase();

    setLoading(true); // Ativa o estado de carregamento

    if (isSignup) {
      // Fluxo de cadastro
      try {
        // Verifica se o nome da loja é único
        const isUnique = await checkStoreNameUnique(storeName);
        if (!isUnique) {
          message.error(
            'Este nome de loja já está em uso. Por favor, escolha outro.'
          );
          setLoading(false);
          return;
        }

        // Cria o usuário com email e senha no Firebase Auth
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        const uid = user.uid;

        // Cria o documento do jogador no Firestore
        await createPlayerDocument(uid, storeName, playerName, whatsapp);

        message.success('Cadastro realizado com sucesso!');

        // Navega para o dashboard após um pequeno delay
        setTimeout(() => {
          navigate('/dashboard');
        }, 1500);
      } catch (error) {
        console.error('Erro no cadastro:', error);
        message.error('Erro no cadastro: ' + error.message);
      } finally {
        setLoading(false); // Desativa o estado de carregamento
      }
    } else {
      // Fluxo de login existente
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        const uid = user.uid;

        const userDoc = await getDoc(doc(firestore, 'players', uid));
        if (!userDoc.exists()) {
          await signOut(auth);
          message.error(
            'Usuário não encontrado no sistema, por favor faça login novamente.'
          );
        } else {
          // Navegação após login bem-sucedido
          message.success('Login realizado com sucesso!');
          setTimeout(() => {
            navigate('/dashboard');
          }, 1500);
        }
      } catch (error) {
        console.error('Erro no login:', error);
        message.error('Erro no login: ' + error.message);
      } finally {
        setLoading(false); // Desativa o estado de carregamento
      }
    }
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        overflowX: 'hidden', // Previne overflow horizontal
      }}
    >
      <Row style={{ width: '100%' }}>
        {/* Condicional para renderizar Sider somente em telas grandes */}
        {screens.md && (
          <Col
            md={12}
            lg={12}
            style={{
              backgroundColor: '#000',
              overflow: 'hidden',
              padding: 0,
            }}
          >
            <video
              autoPlay
              muted
              loop
              playsInline
              style={{
                width: '100%',
                height: '100vh',
                objectFit: 'cover',
              }}
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Media%2Fmkt%2Finshot.mp4?alt=media&token=529d7dc4-bcfe-4e6a-8bde-4d4530a9e5da"
                type="video/mp4"
              />
              Seu navegador não suporta o vídeo.
            </video>
          </Col>
        )}

        <Col
          xs={24}
          sm={24}
          md={screens.md ? 12 : 24}
          lg={screens.md ? 12 : 24}
          style={{
            backgroundColor: '#f1f1f1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '24px',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '24px' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl5.png?alt=media&token=2fd15d80-371a-43a4-93a6-3b73409f7d68"
              alt="Logo"
              style={{ maxWidth: '350px', width: '100%' }}
            />
          </div>
          <div
            style={{
              maxWidth: '350px',
              width: '100%',
              padding: '24px',
              backgroundColor: '#f6f6f6',
              borderRadius: '10px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
            }}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={handleAuth}
              style={{ width: '100%' }}
            >
              {isSignup && (
                <>
                  {/* Campo para o Nome da Loja */}
                  <Form.Item
                    name="storeName"
                    label={<span style={{ color: '#ccc' }}>Nome da Loja</span>}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira o nome da sua loja',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nome da Loja"
                      style={{
                        borderRadius: '6px',
                        backgroundColor: '#fff',
                        color: '#000',
                      }}
                    />
                  </Form.Item>

                  {/* Campo para o Nome do Jogador */}
                  <Form.Item
                    name="playerName"
                    label={<span style={{ color: '#ccc' }}>Nome do Jogador</span>}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira seu nome',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Seu Nome"
                      style={{
                        borderRadius: '6px',
                        backgroundColor: '#fff',
                        color: '#000',
                      }}
                    />
                  </Form.Item>

                  {/* Campo para o WhatsApp */}
                  <Form.Item
                    name="whatsapp"
                    label={<span style={{ color: '#ccc' }}>WhatsApp</span>}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira seu número de WhatsApp',
                      },
                      {
                        pattern: /^[+]?[0-9]{10,15}$/,
                        message: 'Por favor, insira um número de WhatsApp válido',
                      },
                    ]}
                  >
                    <Input
                      placeholder="(DDD) + Seu Número"
                      style={{
                        borderRadius: '6px',
                        backgroundColor: '#fff',
                        color: '#000',
                      }}
                    />
                  </Form.Item>
                </>
              )}

              {/* Campo para o Email */}
              <Form.Item
                name="email"
                label={<span style={{ color: '#ccc' }}>Email</span>}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Por favor, insira um email válido',
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  style={{
                    borderRadius: '6px',
                    backgroundColor: '#fff',
                    color: '#000',
                  }}
                />
              </Form.Item>

              {/* Campo para a Senha */}
              <Form.Item
                name="password"
                label={<span style={{ color: '#ccc' }}>Senha</span>}
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira sua senha',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Senha"
                  style={{
                    borderRadius: '6px',
                    backgroundColor: '#fff',
                    color: '#000',
                  }}
                />
              </Form.Item>

              {/* Botão de Submit */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  style={{
                    backgroundColor: '#202020',
                    height: '40px',
                    fontSize: '16px',
                    borderRadius: '6px',
                    color: '#fff',
                  }}
                >
                  {isSignup ? 'Cadastrar' : 'Entrar'}
                </Button>
              </Form.Item>
            </Form>

            {/* Botão para alternar entre Login e Signup */}
            <div style={{ textAlign: 'center', marginTop: '16px' }}>
              <Button
                type="link"
                onClick={() => {
                  setIsSignup(!isSignup);
                  form.resetFields();
                }}
                disabled={loading}
                style={{ color: '#202020', fontSize: '14px' }}
              >
                {isSignup
                  ? 'Já possui uma conta? Entre aqui'
                  : 'Não possui uma conta? Cadastre-se aqui'}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default AuthScreen;
