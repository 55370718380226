// src/components/FloatingClientCard.js

import React, { useState, useEffect } from 'react';
import { Card, Typography, Button } from 'antd';
import { UserOutlined, CloseOutlined, MinimizeOutlined } from '@ant-design/icons';
import './FloatingClientCard.css'; // Importa o arquivo de estilos

const { Text, Title } = Typography;

const FloatingClientCard = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [client, setClient] = useState(null);

  // Função para atualizar o cliente a partir do localStorage
  const updateClient = () => {
    const storedClient = localStorage.getItem('selectedClient');
    if (storedClient) {
      const parsedClient = JSON.parse(storedClient);
      console.log('Cliente atualizado:', parsedClient); // Log para depuração
      setClient(parsedClient);
    } else {
      console.log('Nenhum cliente selecionado'); // Log para depuração
      setClient(null);
    }
  };

  // Recupera os dados do cliente selecionado ao montar o componente
  useEffect(() => {
    console.log('FloatingClientCard montado'); // Log para depuração
    updateClient();

    // Adiciona um listener para o evento 'clientSelected'
    const handleClientSelected = () => {
      console.log('Evento "clientSelected" recebido'); // Log para depuração
      updateClient();
    };

    window.addEventListener('clientSelected', handleClientSelected);

    // Limpa o listener ao desmontar o componente
    return () => {
      window.removeEventListener('clientSelected', handleClientSelected);
    };
  }, []);

  const toggleCard = () => {
    setIsExpanded(!isExpanded);
  };

  const closeCard = () => {
    setIsExpanded(false);
  };

  if (!client) {
    return null; // Não renderiza o botão se nenhum cliente estiver selecionado
  }

  return (
    <div className="floating-container">
      {/* Botão Circular */}
      {!isExpanded && (
            <Button
                shape="circle"
                icon={<UserOutlined />}
                size="large"
                onClick={toggleCard}
                className="circle-button"
                style={{
                backgroundColor: '#202020', // Muda a cor de fundo do círculo
                borderColor: '#202020', // Muda a cor da borda do círculo
                color: '#fff', // Muda a cor do ícone
                }}
            />
            )}


      {/* Card Expandido */}
      {isExpanded && (
        <Card
          title={`Cliente: ${client.nome}`}
          extra={
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={closeCard}
              className="close-button"
            />
          }
          className="client-card"
        >
          <div style={{ marginBottom: '8px' }}>
            <Text><strong>Marca Preferida:</strong> {client.marca}</Text>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <Text><strong>Tipo:</strong> {client.tipo}</Text>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <Text><strong>Ano Mínimo:</strong> {client.anoMin}</Text>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <Text><strong>Potência Desejada:</strong> {client.potencia}cv</Text>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <Text><strong>Valor Máximo:</strong> R$ {client.valorMax.toLocaleString('pt-BR')}</Text>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <Text><strong>Km Máximo:</strong> {client.km}</Text>
          </div>
          
        </Card>
      )}
    </div>
  );
};

export default FloatingClientCard;
