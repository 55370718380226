// PlayerRankingScreen.jsx
import React, { useEffect, useState } from 'react';
import {
  Layout,
  Card,
  Table,
  Typography,
  message,
  Avatar,
  Spin,
  Button,
  Menu,
  Modal,
  Select,
  Drawer,
} from 'antd';
import {
  AppstoreOutlined,
  CarOutlined,
  ShoppingFilled,
  ShopOutlined,
  UserOutlined,
  BankOutlined,
  CompassOutlined,
  NotificationOutlined,
  FundOutlined,
  StarOutlined,
  DollarOutlined,
  CrownOutlined,
  ShoppingOutlined,
  GoldOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { firestore } from './firebaseConfig';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const PlayerRankingScreen = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortBy, setSortBy] = useState('totalProfit'); // Estado para controlar a ordenação
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRankingData = async () => {
      const user = auth.currentUser;
      if (user) {
        setCurrentUserId(user.uid);
        try {
          // Cria uma consulta ordenada pelo critério selecionado
          const q = query(
            collection(firestore, 'players'),
            orderBy(`metrics.${sortBy}`, 'desc')
          );
          const querySnapshot = await getDocs(q);
          const playersData = querySnapshot.docs.map((doc) => ({
            key: doc.id,
            id: doc.id,
            name: doc.data().name || 'Jogador Desconhecido',
            totalProfit: doc.data().metrics?.totalProfit ?? null,
            totalRevenue: doc.data().metrics?.totalRevenue ?? null,
            totalSales: doc.data().metrics?.totalSales ?? null,
            balance: doc.data().balance ?? null, // Corrigido balance
            dailySales: doc.data().metrics?.dailySales ?? null, // Corrigido dailySales
            carsBought: doc.data().metrics?.carrosComprados ?? null, // Adicionado carrosComprados
            totalPurchases: doc.data().metrics?.totalPurchases ?? null, // Adicionado totalPurchases
            avatarUrl: doc.data().avatarUrl || null, // Supondo que há um campo avatarUrl
          }));
          setPlayers(playersData);
          setLoading(false);
        } catch (error) {
          message.error('Erro ao carregar o ranking de jogadores.');
          console.error('Erro ao carregar o ranking de jogadores:', error);
          setLoading(false);
        }
      } else {
        message.error('Usuário não autenticado.');
        setLoading(false);
      }
    };
    fetchRankingData();
  }, [auth, sortBy]); // Dependência adicionada para refetch quando sortBy muda

  const columns = [
    {
      title: 'Posição',
      dataIndex: 'position',
      key: 'position',
      render: (text, record, index) => (
        <Text
          strong
          style={{
            color: record.id === currentUserId ? '#1890ff' : 'inherit',
          }}
        >
          {index + 1}
        </Text>
      ),
      width: 100,
      align: 'center',
    },
    {
      title: 'Jogador',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={record.avatarUrl}
            icon={!record.avatarUrl && <UserOutlined />}
            style={{ marginRight: '10px' }}
          />
          {record.group === 2 && <CrownOutlined style={{ color: '#fadb14', marginRight: '5px' }} />}
          <Text
            strong
            style={{
              color: record.id === currentUserId ? '#1890ff' : 'inherit',
            }}
          >
            {text}
          </Text>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 250,
    },
    {
      title: 'Lucro Total',
      dataIndex: 'totalProfit',
      key: 'totalProfit',
      render: (value) => (
        value !== null ? (
          <span>
            <DollarOutlined style={{ color: '#52c41a', marginRight: '4px' }} />
            {value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        ) : <Text>N/A</Text>
      ),
      sorter: (a, b) => (a.totalProfit ?? 0) - (b.totalProfit ?? 0),
      align: 'right',
      width: 200,
    },
    {
      title: 'Receita Total',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      render: (value) => (
        value !== null ? (
          <span>
            <DollarOutlined style={{ color: '#1890ff', marginRight: '4px' }} />
            {value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        ) : <Text>N/A</Text>
      ),
      sorter: (a, b) => (a.totalRevenue ?? 0) - (b.totalRevenue ?? 0),
      align: 'right',
      width: 200,
    },
    {
      title: 'Vendas Totais',
      dataIndex: 'totalSales',
      key: 'totalSales',
      render: (value) => (value !== null ? <Text>{value}</Text> : <Text>N/A</Text>),
      sorter: (a, b) => (a.totalSales ?? 0) - (b.totalSales ?? 0),
      align: 'right',
      width: 150,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      render: (value) => (
        value !== null ? (
          <span>
            <DollarOutlined style={{ color: '#faad14', marginRight: '4px' }} />
            {value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        ) : <Text>N/A</Text>
      ),
      sorter: (a, b) => (a.balance ?? 0) - (b.balance ?? 0),
      align: 'right',
      width: 200,
    },
    {
      title: 'Carros Comprados',
      dataIndex: 'carsBought',
      key: 'carsBought',
      render: (value) => (value !== null ? <Text>{value}</Text> : <Text>N/A</Text>),
      sorter: (a, b) => (a.carsBought ?? 0) - (b.carsBought ?? 0),
      align: 'right',
      width: 150,
    },
  ];

  // Adiciona a posição ao array de jogadores
  const dataSource = players.map((player, index) => ({
    ...player,
    position: index + 1,
  }));

  // Função para mostrar o modal de ajuda
  const showHelpModal = () => {
    setIsModalVisible(true);
  };

  // Função para fechar o modal de ajuda
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // Função para lidar com o logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('Logout bem-sucedido');
        navigate('/auth'); // Redireciona para a página de login
      })
      .catch((error) => {
        console.error('Erro ao sair:', error);
        message.error('Erro ao realizar logout.');
      });
  };

  // Função para lidar com a mudança de ordenação
  const handleSortChange = (value) => {
    setSortBy(value);
    setLoading(true); // Mostrar o spinner durante o refetch
  };

  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '23px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<CarOutlined style={{ fontSize: '23px' }} />} title="Showroom">
        <Link to="/showroom">Showroom</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<ShopOutlined style={{ fontSize: '23px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<BankOutlined style={{ fontSize: '26px' }} />} title="Banco">
        <Link to="/bank">Banco</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '23px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '23px' }} />} title="Ranking">
        <Link to="/ranking">Ranking</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<ShoppingFilled style={{ fontSize: '23px' }} />} title="Loja">
        <Link to="/shop">Loja</Link>
      </Menu.Item>
      
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Header */}
      <Header
        style={{
          background: '#080808',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
        }}
      >
        {/* Seção da Esquerda: Logo e Botão "Como jogar" */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <div
            className="logo"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl6.png?alt=media&token=730338a6-0f61-4345-bca3-b03bd4aab180"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px', maxWidth: '100%' }}
            />
          </div>
        </div>

        {/* Menu de Navegação */}
        <Button
          type="text"
          icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
          onClick={() => setIsDrawerVisible(true)}
          className="drawer-toggle-button"
        />
      </Header>

      {/* Drawer para Navegação Mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical">{renderMenuItems()}</Menu>
      </Drawer>

      {/* Content */}
      <Content
        style={{
          padding: '24px',
          backgroundColor: '#f0f2f5',
          flex: '1 0 auto',
        }}
      >
        <Card
          title={<Title level={3}>Ranking de Jogadores</Title>}
          style={{
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
          }}
        >
          {/* Seletor de Ordenação */}
          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Text style={{ marginRight: '8px', fontWeight: '500' }}>
              Ordenar por:
            </Text>
            <Select
              value={sortBy}
              onChange={handleSortChange}
              style={{ width: 200 }}
            >
              <Option value="totalProfit">Lucro Total</Option>
              <Option value="totalRevenue">Receita Total</Option>
              <Option value="totalSales">Vendas Totais</Option>
              <Option value="balance">Saldo</Option>
              <Option value="carsBought">Carros Comprados</Option>
            </Select>
          </div>

          {loading ? (
            <div style={{ textAlign: 'center', padding: '50px 0' }}>
              <Spin size="large" tip="Carregando Ranking..." />
            </div>
          ) : (
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{ pageSize: 10 }}
              rowClassName={(record) =>
                record.id === currentUserId ? 'highlight-row' : ''
              }
              bordered
              scroll={{ x: 'max-content' }}
              locale={{
                emptyText: 'Nenhum dado disponível.',
              }}
              style={{ backgroundColor: '#ffffff' }}
            />
          )}
        </Card>
      </Content>

      {/* Custom Styles */}
      <style jsx="true">{`
        .highlight-row {
          background-color: #e6f7ff !important;
        }
      `}</style>
    </Layout>
  );
};

export default PlayerRankingScreen;