import React, { useState } from 'react';
import { Modal, Button, Typography, Row, Col, Card, Badge, Grid } from 'antd';

const { Paragraph, Link, Title } = Typography;
const { useBreakpoint } = Grid;

const Shopcar = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const screens = useBreakpoint();

  // Array interno de dados dos carros
  const cars = [
    {
      id: '1',
      title: 'Santíssima Trindade',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2FSilverstoneP1LaF918.jpg?alt=media&token=daf86839-fe33-4022-84ea-35d37793cf43',
      realPrice: 30,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      unitsLeft: 3, // Quantidade de unidades restantes
    },
    
    {
      id: '3',
      title: 'GTR R32 + R33 + R34 + R35',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2Fnissan-gt-r-shootout.jpg?alt=media&token=ca6274e8-9060-4bb6-b988-cd89f7f3c4c8',
      
      realPrice: 7,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      unitsLeft: 3, // Quantidade de unidades restantes
    },
    
    {
      id: '6',
      title: 'Audi Sport Quattro',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2F1984-Audi-Sport-Quattro-005-1080.jpg?alt=media&token=d3c07757-1278-4d2f-a0db-2e779ff5a2f3',
      
      realPrice: 3,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      unitsLeft: 3, // Quantidade de unidades restantes
    },
    {
      id: '7',
      title: 'Jaguar E-Type Roadster',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2F1965-Jaguar-E-Type-Roadster-001-1080.jpg?alt=media&token=448d55ad-46ce-4665-82df-fad6b870e138',
      
      realPrice: 3,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      unitsLeft: 3, // Quantidade de unidades restantes
    },
    {
      id: '8',
      title: 'Aston Martin One-77',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2F2010-Aston-Martin-One-77-001-1080.jpg?alt=media&token=f49a75c7-804d-4993-8943-3df87f6fbdff',
      
      realPrice: 3,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      unitsLeft: 3, // Quantidade de unidades restantes
    },
    {
      id: '8',
      title: 'Adicione seu carro no jogo',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2F41-410029_car-silhouet-file-car-silhouette-front-png.png?alt=media&token=7360a316-4591-41f1-a79c-ee0b89235d22',
      
      realPrice: 1.99,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      unitsLeft: 3, // Quantidade de unidades restantes
    },
  ];

  // Função para lidar com o clique no card
  const handleCardClick = (car) => {
    setSelectedCar(car);
    setIsModalVisible(true);
  };

  // Função para fechar o modal
  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedCar(null);
  };

  // Função para definir a cor da badge
  const getBadgeColor = (unitsLeft) => {
    if (unitsLeft <= 1) return '#f5222d'; // Vermelho
    if (unitsLeft <= 3) return '#1890ff'; // Azul
    return '#52c41a'; // Verde
  };

  return (
    <div style={{ padding: '20px', boxSizing: 'border-box', width: '100%', overflowX: 'hidden' }}>
      <Title level={4} style={{ textAlign: 'left', marginBottom: '20px', color: '#333333', fontSize: '1.5em' }}>
        Carros exclusivos
      </Title>
      <Row gutter={[16, 16]}>
        {cars.map((car) => (
          <Col
            key={car.id}
            xs={24}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Badge
              
            >
              <Card
                hoverable
                cover={
                  <img
                    alt={car.title}
                    src={car.imageUrl}
                    style={{ height: '200px', objectFit: 'cover', width: '100%' }}
                  />
                }
                onClick={() => handleCardClick(car)}
                style={{
                  width: '100%',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  position: 'relative', // Necessário para a badge ficar em cima
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
                }}
              >
                <Card.Meta
                  title={car.title}
                  description={
                    <div>
                      
                      
                      <Typography.Text style={{ color: '#777777', fontSize: '1em' }}>
                        PIX: R$ {car.realPrice.toLocaleString('pt-BR')}
                      </Typography.Text>
                    </div>
                  }
                />
              </Card>
            </Badge>
          </Col>
        ))}
      </Row>

      {/* Modal Informativo */}
      <Modal
        title="Informações de Compra"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="fechar" type="primary" onClick={handleModalClose}>
            Fechar
          </Button>,
        ]}
        centered
      >
        {selectedCar && (
          <div>
            <Paragraph>
              O sistema de compras automáticas está em desenvolvimento. Para comprar este carro, faça o PIX para{' '}
              <strong>{selectedCar.pixKey || 'SUA_CHAVE_PIX_AQUI'}</strong> e envie o comprovante para o{' '}
              <Link
                href={`https://wa.me/${selectedCar.whatsappNumber || 'SEU_NUMERO_WHATSAPP_AQUI'}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp
              </Link>.
            </Paragraph>
            <Paragraph>
              <Link
                href={`https://wa.me/${selectedCar.whatsappNumber || 'SEU_NUMERO_WHATSAPP_AQUI'}?text=Olá,%20gostaria%20de%20comprar%20o%20carro%20${encodeURIComponent(
                  selectedCar.title || 'Selecionado'
                )}.`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Clique aqui para enviar o comprovante.
              </Link>
            </Paragraph>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Shopcar;
