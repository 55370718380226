import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Typography,
  Button,
  Modal,
  Form,
  Slider,
  Progress,
  message,
  Menu,
  Drawer,
  Card,
  Row,
  Col,
} from 'antd';
import {
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  CrownOutlined,
  ShoppingFilled,
  MenuOutlined,
  DollarOutlined,
  BankOutlined,
  PayCircleOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  getDocs,
  writeBatch,
  Timestamp,
} from 'firebase/firestore';
import { firestore } from './firebaseConfig'; // Certifique-se de que este caminho está correto

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const BankLoan = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  // Estados para controlar a visibilidade do Drawer e Modais
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isLoanModalVisible, setIsLoanModalVisible] = useState(false);
  const [isRepaymentModalVisible, setIsRepaymentModalVisible] = useState(false);

  // Estados para armazenar dados financeiros do jogador
  const [balance, setBalance] = useState(0);
  const [debt, setDebt] = useState(0);
  const [loanAmount, setLoanAmount] = useState(10000);
  const [totalRepayment, setTotalRepayment] = useState(0);
  const [repaymentDeadline, setRepaymentDeadline] = useState(null);
  const [loanTakenAt, setLoanTakenAt] = useState(null);

  // Estado para controlar o processamento de ações
  const [isProcessing, setIsProcessing] = useState(false);

  // Estado para a barra de progresso
  const [progressPercent, setProgressPercent] = useState(0);

  // Taxa de juros diária de 10%
  const DAILY_INTEREST_RATE = 0.1;
  const MAX_LOAN_AMOUNT = 1000000; // Valor máximo do empréstimo

  /**
   * Função para calcular a dívida atual com juros compostos.
   * @param {number} initialDebt - Dívida inicial.
   * @param {Timestamp} loanDate - Data em que o empréstimo foi tomado.
   * @returns {number} - Dívida atualizada com juros.
   */
  const calculateDebtWithInterest = useCallback((initialDebt, loanDate) => {
    if (!loanDate || !(loanDate instanceof Timestamp)) return initialDebt;

    const currentDate = new Date();
    const loanTakenDate = loanDate.toDate(); // Convertendo Timestamp para Date
    const timeDiff = currentDate.getTime() - loanTakenDate.getTime();
    const daysPassed = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (daysPassed <= 0) return initialDebt;

    // Aplicando juros compostos: Dívida atual = dívida inicial * (1 + juros)^dias
    const updatedDebt = initialDebt * Math.pow(1 + DAILY_INTEREST_RATE, daysPassed);

    return updatedDebt;
  }, []);

  /**
   * Função para calcular o progresso do prazo de pagamento.
   * @param {Date} deadline - Data limite para pagamento.
   */
  const calculateProgress = useCallback((deadline) => {
    if (!deadline || !loanTakenAt) {
      setProgressPercent(0);
      return;
    }

    const loanTakenDate = loanTakenAt.toDate();
    const totalDuration = deadline.getTime() - loanTakenDate.getTime();
    const timePassed = new Date().getTime() - loanTakenDate.getTime();
    const percent = (timePassed / totalDuration) * 100;

    // Arredondar a porcentagem para duas casas decimais
    const roundedPercent = Math.min(parseFloat(percent.toFixed(2)), 100);
    setProgressPercent(roundedPercent);
  }, [loanTakenAt]);

  /**
   * Função para buscar os dados do jogador no Firestore.
   */
  const fetchPlayerData = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado.');
        navigate('/login');
        return;
      }

      const playerRef = doc(firestore, 'players', user.uid);
      const playerSnap = await getDoc(playerRef);

      if (playerSnap.exists()) {
        const data = playerSnap.data();
        setBalance(data.balance || 0);

        // Calcular dívida atual com juros
        const debtWithInterest = calculateDebtWithInterest(data.debt || 0, data.loanTakenAt);
        setDebt(debtWithInterest);

        setRepaymentDeadline(data.repaymentDeadline?.toDate() || null);
        setLoanTakenAt(data.loanTakenAt || null);
        calculateProgress(data.repaymentDeadline?.toDate() || null);
      } else {
        message.error('Dados do jogador não encontrados.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
      message.error('Erro ao buscar dados do jogador.');
    }
  }, [auth, navigate, calculateDebtWithInterest, calculateProgress]);

  useEffect(() => {
    fetchPlayerData();
  }, [fetchPlayerData]);

  /**
   * Função para solicitar empréstimo.
   */
  const handleLoanRequest = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado.');
        return;
      }

      const playerRef = doc(firestore, 'players', user.uid);
      const newBalance = balance + loanAmount;
      const deadline = new Date();
      deadline.setDate(deadline.getDate() + 7); // Prazo de 7 dias

      // Calcular o total a pagar com juros compostos de 7% ao dia por 7 dias
      const totalRepaymentAmount = loanAmount * Math.pow(1 + DAILY_INTEREST_RATE, 7);
      setTotalRepayment(totalRepaymentAmount);

      await updateDoc(playerRef, {
        balance: newBalance,
        debt: loanAmount, // Armazenar apenas o valor inicial do empréstimo
        repaymentDeadline: deadline,
        loanTakenAt: serverTimestamp(),
      });

      message.success('Empréstimo concedido com sucesso!');
      setIsLoanModalVisible(false);
      fetchPlayerData(); // Atualizar os dados após a solicitação do empréstimo
    } catch (error) {
      console.error('Erro ao processar empréstimo:', error);
      message.error('Erro ao processar empréstimo.');
    } finally {
      setIsProcessing(false);
    }
  };

  /**
   * Função para realizar o pagamento da dívida.
   */
  const handleRepayment = async () => {
    if (isProcessing) return;
    if (balance < debt) {
      message.error('Saldo insuficiente para pagar a dívida.');
      return;
    }
    setIsProcessing(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado.');
        return;
      }

      const playerRef = doc(firestore, 'players', user.uid);
      const newBalance = balance - debt;

      await updateDoc(playerRef, {
        balance: newBalance,
        debt: 0,
        repaymentDeadline: null,
        loanTakenAt: null,
      });

      message.success('Dívida paga com sucesso!');
      setIsRepaymentModalVisible(false);
      fetchPlayerData(); // Atualizar os dados após o pagamento da dívida
    } catch (error) {
      console.error('Erro ao processar pagamento:', error);
      message.error('Erro ao processar pagamento.');
    } finally {
      setIsProcessing(false);
    }
  };

  /**
   * Função para lidar com dívidas vencidas.
   */
  const handleOverdueDebt = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado.');
        return;
      }

      const playerRef = doc(firestore, 'players', user.uid);
      const playerSnap = await getDoc(playerRef);

      if (!playerSnap.exists()) {
        message.error('Dados do jogador não encontrados.');
        return;
      }

      const data = playerSnap.data();
      let currentDebt = data.debt || 0;
      const loanTakenDate = data.loanTakenAt?.toDate();
      const deadline = data.repaymentDeadline?.toDate();

      if (!loanTakenDate || !deadline) return;

      const currentDate = new Date();
      if (currentDate <= deadline) return; // Ainda não venceu

      // Calcular dias passados desde o prazo
      const timeDiff = currentDate.getTime() - deadline.getTime();
      const daysOverdue = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      // Aplicar juros compostos de 7% ao dia
      currentDebt = currentDebt * Math.pow(1 + DAILY_INTEREST_RATE, daysOverdue);

      let updatedBalance = data.balance || 0;
      let updatedDebt = currentDebt;

      if (updatedBalance >= updatedDebt) {
        updatedBalance -= updatedDebt;
        updatedDebt = 0;
        message.success('Dívida paga automaticamente com o saldo.');
      } else {
        updatedDebt -= updatedBalance;
        updatedBalance = 0;
        message.warning('Saldo insuficiente. Carros serão vendidos para cobrir a dívida.');

        // Lógica para vender carros até cobrir a dívida
        const carsRef = collection(firestore, 'players', user.uid, 'colecaopessoal');
        const carsSnap = await getDocs(carsRef);

        const batch = writeBatch(firestore);
        for (const carDoc of carsSnap.docs) {
          const carData = carDoc.data();
          const carValue = carData.valor || 0;
          if (updatedDebt <= 0) break;

          batch.delete(carDoc.ref);
          updatedDebt -= carValue;
          message.info(`Carro ${carData.modelo} vendido por R$${carValue.toLocaleString('pt-BR')}.`);
        }

        await batch.commit();

        if (updatedDebt > 0) {
          message.error('Dívida ainda não foi totalmente coberta.');
        } else {
          message.success('Dívida totalmente coberta após a venda de carros.');
          updatedDebt = 0;
        }
      }

      // Atualizar dados no Firestore
      await updateDoc(playerRef, {
        balance: updatedBalance,
        debt: updatedDebt,
        repaymentDeadline: null,
        loanTakenAt: null,
      });

      setBalance(updatedBalance);
      setDebt(updatedDebt);
      setRepaymentDeadline(null);
      setLoanTakenAt(null);
      setProgressPercent(0);
      message.success('Dívida atualizada com sucesso.');
    } catch (error) {
      console.error('Erro ao lidar com dívida vencida:', error);
      message.error('Erro ao lidar com dívida vencida.');
    }
  }, [auth]);

  /**
   * Efeito para verificar dívidas vencidas e calcular o progresso.
   */
  useEffect(() => {
    if (debt > 0 && repaymentDeadline) {
      const now = new Date();
      if (now > repaymentDeadline) {
        handleOverdueDebt();
      } else {
        calculateProgress(repaymentDeadline);
      }
    }
  }, [debt, repaymentDeadline, handleOverdueDebt, calculateProgress]);

  /**
   * Função para renderizar os itens do menu
   */
  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '26px' }} />} title="Dashboard">
        <Link to="/dashboard"></Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<CarOutlined style={{ fontSize: '26px' }} />} title="Showroom">
        <Link to="/showroom"></Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<ShopOutlined style={{ fontSize: '26px' }} />} title="Mercado">
        <Link to="/market"></Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<BankOutlined style={{ fontSize: '26px' }} />} title="Banco">
        <Link to="/bank"></Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '26px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal"></Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '26px' }} />} title="Ranking">
        <Link to="/ranking"></Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<ShoppingFilled style={{ fontSize: '26px' }} />} title="Loja">
        <Link to="/shop"></Link>
      </Menu.Item>
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Topbar Personalizada */}
      <Header
        style={{
          background: '#080808',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
        }}
      >
        {/* Seção da Esquerda: Logo */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl6.png?alt=media&token=730338a6-0f61-4345-bca3-b03bd4aab180"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px', maxWidth: '100%' }}
            />
          </div>
        </div>

        {/* Menu de Navegação */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Menu theme="dark" mode="horizontal" style={{ background: '#080808', lineHeight: '64px' }}>
            {renderMenuItems()}
          </Menu>
          {/* Botão para abrir o Drawer em telas menores */}
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={() => setIsDrawerVisible(true)}
            className="drawer-toggle-button"
            style={{ display: 'none' }} // Escondido em telas maiores
          />
        </div>
      </Header>

      {/* Drawer para Navegação Mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical">
          {renderMenuItems()}
        </Menu>
      </Drawer>

      {/* Conteúdo Principal */}
      <Content style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
        {/* Saldo e Dívida */}
        <Row gutter={[16, 16]} justify="center">
          {/* Saldo do Jogador */}
          <Col xs={24} sm={12} md={8}>
            <Card
              style={{
                borderRadius: '12px',
                background: '#ffffff',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
              bordered={false}
            >
              <Row align="middle">
                <Col span={6}>
                  <DollarOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
                </Col>
                <Col span={18}>
                  <Title level={5}>Saldo</Title>
                  <Text strong style={{ fontSize: '18px' }}>
                    R$ {balance.toLocaleString('pt-BR')}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* Dívida Atual */}
          <Col xs={24} sm={12} md={8}>
            <Card
              style={{
                borderRadius: '12px',
                background: '#ffffff',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
              bordered={false}
            >
              <Row align="middle">
                <Col span={6}>
                  <BankOutlined style={{ fontSize: '32px', color: '#ff4d4f' }} />
                </Col>
                <Col span={18}>
                  <Title level={5}>Dívida</Title>
                  <Text strong style={{ fontSize: '18px', color: '#ff4d4f' }}>
                    R$ {debt.toLocaleString('pt-BR')}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* Barra de Progresso */}
        {debt > 0 && (
          <Row justify="center" style={{ marginTop: '24px' }}>
            <Col xs={24} sm={24} md={16}>
              <Card
                style={{
                  borderRadius: '12px',
                  background: '#ffffff',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
                bordered={false}
              >
                <Title level={5}>Progresso do Prazo de Pagamento</Title>
                <Progress
                  percent={progressPercent}
                  status={progressPercent < 100 ? 'active' : 'exception'}
                  strokeColor={progressPercent < 100 ? '#1890ff' : '#ff4d4f'}
                  showInfo={true}
                  format={(percent) => `${percent}%`}
                />
                <Text type={progressPercent < 100 ? 'secondary' : 'danger'}>
                  {progressPercent < 100
                    ? `Você tem ${7 - Math.floor((progressPercent / 100) * 7)} dias restantes para pagar a dívida.`
                    : 'O prazo para pagamento da dívida expirou.'}
                </Text>
                {/* Botão para pagar a dívida */}
                <Button
                  type="primary"
                  icon={<PayCircleOutlined />}
                  onClick={() => setIsRepaymentModalVisible(true)}
                  style={{ marginTop: '16px' }}
                >
                  Pagar Dívida
                </Button>
              </Card>
            </Col>
          </Row>
        )}

        {/* Solicitação de Empréstimo */}
        <Row justify="center" style={{ marginTop: '24px' }}>
          <Col xs={24} sm={24} md={16}>
            <Card
              title={<Title level={4} style={{ margin: 0 }}>Solicitar Empréstimo</Title>}
              style={{
                borderRadius: '12px',
                background: '#ffffff',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
              bordered={false}
            >
              <Form layout="vertical">
                {/* Slider para seleção do valor do empréstimo */}
                <Form.Item label="Valor do Empréstimo (R$)">
                  <Slider
                    min={10000}
                    max={MAX_LOAN_AMOUNT}
                    step={10000}
                    onChange={(value) => setLoanAmount(value)}
                    value={loanAmount}
                    tooltipVisible
                  />
                  <Text strong>R$ {loanAmount.toLocaleString('pt-BR')}</Text>
                </Form.Item>

                {/* Exibição do total a pagar */}
                <Form.Item label="Total a Pagar em 7 Dias (com 10% ao dia de juros)">
                  <Text strong style={{ fontSize: '18px' }}>
                    R$ {(
                      loanAmount * Math.pow(1 + DAILY_INTEREST_RATE, 7)
                    ).toLocaleString('pt-BR')}
                  </Text>
                </Form.Item>

                {/* Botão para solicitar empréstimo */}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => setIsLoanModalVisible(true)}
                    disabled={debt > 0}
                    style={{
                      backgroundColor: debt > 0 ? '#d9d9d9' : '#1890ff',
                      borderColor: debt > 0 ? '#d9d9d9' : '#1890ff',
                      width: '100%',
                      height: '40px',
                      borderRadius: '8px',
                    }}
                  >
                    {debt > 0 ? 'Empréstimo Ativo' : 'Solicitar Empréstimo'}
                  </Button>
                </Form.Item>

                {/* Tooltip informando sobre empréstimo ativo */}
                {debt > 0 && (
                  <Text type="warning">
                    Você já possui um empréstimo ativo e precisa quitá-lo antes de solicitar outro.
                  </Text>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* Modal de Confirmação de Empréstimo */}
      <Modal
        title="Confirmar Empréstimo"
        visible={isLoanModalVisible}
        onCancel={() => setIsLoanModalVisible(false)}
        onOk={handleLoanRequest}
        okText="Confirmar"
        cancelText="Cancelar"
        confirmLoading={isProcessing}
        centered
        width={500}
        bodyStyle={{ padding: '24px' }}
      >
        <Text>
          Você está solicitando um empréstimo de{' '}
          <strong>R$ {loanAmount.toLocaleString('pt-BR')}</strong>. Com uma taxa de juros de 10% ao dia, você deverá pagar um total de{' '}
          <strong>R$ {(
            loanAmount * Math.pow(1 + DAILY_INTEREST_RATE, 7)
          ).toLocaleString('pt-BR')}</strong> em 7 dias.
        </Text>
      </Modal>

      {/* Modal de Confirmação de Pagamento */}
      <Modal
        title="Confirmar Pagamento"
        visible={isRepaymentModalVisible}
        onCancel={() => setIsRepaymentModalVisible(false)}
        onOk={handleRepayment}
        okText="Pagar"
        cancelText="Cancelar"
        confirmLoading={isProcessing}
        centered
        width={500}
        bodyStyle={{ padding: '24px' }}
      >
        <Text>
          Você está prestes a pagar sua dívida de{' '}
          <strong>R$ {debt.toLocaleString('pt-BR')}</strong>. Deseja continuar?
        </Text>
      </Modal>
    </Layout>
  );
};

export default BankLoan;
