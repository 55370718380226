import React, { useState } from 'react'; // Adicionando useState e React
import {
  Layout,
  Menu,
  Row,
  Col,
  Card,
  Typography,
  Button,
  Divider,
  Modal,
  List,
  Drawer,
  Grid,
} from 'antd'; // Adicionando Drawer e Grid
import { Link, useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
  ShoppingOutlined,
  LogoutOutlined,
  DollarOutlined,
  CrownOutlined,
  UserOutlined,
  ShoppingFilled,
  MenuOutlined,
} from '@ant-design/icons';
import { getAuth, signOut } from 'firebase/auth';
import Shopcar from './Shopcar';

import { auth } from './firebaseConfig';
import CarCarousel from './CarCarousel';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const Shop = () => {
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false); // Definindo o estado local do modal
  const [isDrawerVisible, setIsDrawerVisible] = useState(false); // Estado para o Drawer
  const navigate = useNavigate();
  const screens = useBreakpoint();

  // Funções para controlar a visibilidade do modal
  const showHelpModal = () => {
    setIsHelpModalVisible(true);
  };

  const handleHelpModalOk = () => {
    setIsHelpModalVisible(false);
  };

  const handleHelpModalCancel = () => {
    setIsHelpModalVisible(false);
  };

  // Funções para controlar o Drawer
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  

  // Dados para os planos de assinatura
  const subscriptionPlans = [
    {
      title: 'Million Pack',
      price: 'R$19.90',
      benefits: [
        '+R$4.000.000 de saldo',
        'Lucro DOBRADO por 3 dias ',
        '+4 vagas (Economiza R$900 mil)',
        'Escolha um carro de até R$1 milhão',
      ],
      buttonText: 'Turbinar a loja',
      buttonLink: 'https://pay.kirvano.com/04384a7e-b549-4bf1-af79-64f0c19cce02',
    },
    {
      title: 'Billion Pack',
      price: 'R$29.90',
      
      benefits: [
        '+R$6.000.000 de saldo',
        'Lucro TRIPLICADO por 6 dias ',
        'Escolha um carro de até R$2 milhões',
        '+8 vagas (Economiza R$3.5mi)',
      ],
      buttonText: 'Turbinar a loja',
      buttonLink: 'https://pay.kirvano.com/1976dbb0-1fb9-4440-90a0-5ad7a9da5455',
    },
    {
      title: 'Trillion Pack',
      price: 'R$39.90',
      
      benefits: [
        '+R$12.000.000 de saldo',
        'Lucro TRIPLICADO por 14 dias ',
        'Escolha um carro de até R$3 milhões',
        '+10 vagas (Economiza R$5.4mi)',
      ],
      buttonText: 'Turbinar a loja',
      buttonLink: 'https://pay.kirvano.com/181814d6-b631-450d-b354-4b1614ee9ec3',
    },
    
  ];

  // Função para renderizar os itens do menu
  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '26px' }} />} title="Dashboard">
        <Link to="/dashboard"></Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<CarOutlined style={{ fontSize: '26px' }} />} title="Showroom">
        <Link to="/showroom"></Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<ShopOutlined style={{ fontSize: '26px' }} />} title="Mercado">
        <Link to="/market"></Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '26px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal"></Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '26px' }} />} title="Ranking">
        <Link to="/ranking"></Link>
      </Menu.Item>  
      <Menu.Item key="6" icon={<ShoppingFilled style={{ fontSize: '26px' }} />} title="Loja">
        <Link to="/shop"></Link>
      </Menu.Item>
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {/* Seção da Esquerda: Logo */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl6.png?alt=media&token=730338a6-0f61-4345-bca3-b03bd4aab180"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px', maxWidth: '100%' }}
            />
          </div>

          {/* Espaço vazio para alinhar os elementos corretamente */}
          <Menu theme="dark" mode="horizontal" style={{ background: '#080808', lineHeight: '64px' }}>
            {/* Placeholder */}
          </Menu>
        </div>

        {/* Seção da Direita: Menu ou Botão de Menu Mobile */}
        {screens.md ? (
          <Menu theme="dark" mode="horizontal" style={{ background: '#080808', lineHeight: '64px' }}>
            {renderMenuItems()}
          </Menu>
        ) : (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={showDrawer}
            className="drawer-toggle-button"
          />
        )}
      </Header>

      {/* Drawer para navegação mobile */}
      <Drawer
          title="Menu de Navegação"
          placement="right"
          onClose={() => setIsDrawerVisible(false)}
          visible={isDrawerVisible}
          className="mobile-menu-drawer"
        >
          <Menu mode="vertical">
            {/* Itens do menu (mesmos do menu desktop) */}
            <Menu.Item key="1" icon={<AppstoreOutlined />} title="Dashboard">
              <Link to="/dashboard">Painel</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<CarOutlined/>} title="Showroom">
              <Link to="/showroom">Showroom</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<ShopOutlined />} title="Mercado">
              <Link to="/market">Mercado</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<CrownOutlined />} title="M">
              <Link to="/market">Ranking</Link>
            </Menu.Item>
            <Menu.Item key="5" icon={<UserOutlined />} title="Coleçao Pessoal">
              <Link to="/colecaopessoal">Coleção Pessoal</Link>
            </Menu.Item>
            
            <Menu.Item key="6s" icon={<ShoppingOutlined />} title="Loja">
              <Link to="/shop">Loja do Jogo</Link>
            </Menu.Item>
            {/* Outros itens do menu */}
          </Menu>
        </Drawer>

      <Layout>
        <Shopcar />

        <Content
          style={{
            margin: '24px 16px 0',
            padding: 24,
            background: '#f0f2f5',
            minHeight: 360,
            boxSizing: 'border-box',
          }}
        >
          {/* Seção de Planos de Assinatura */}
          <Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>
            Turbine sua loja 
          </Title>
          <Row gutter={[16, 16]} justify="center">
            {subscriptionPlans.map((plan, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <Card
                  bordered={false}
                  style={{
                    borderRadius: '4px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    background: '#ffffff',
                  }}
                  hoverable
                >
                  <Title level={4} style={{ textAlign: 'center', color: '#6369D1' }}>
                    {plan.title}
                  </Title>
                  <Title level={2} style={{ textAlign: 'center', color: '#202020' }}>
                    {plan.price}
                  </Title>
                  
                  <Divider />
                  <List
                    size="small"
                    dataSource={plan.benefits}
                    renderItem={(item) => <List.Item style={{ padding: '4px 0' }}>{item}</List.Item>}
                    style={{ padding: '0 16px' }}
                  />
                  <div style={{ textAlign: 'center', marginTop: '16px' }}>
                    <Button
                      type="primary"
                      style={{ backgroundColor: '#202020', borderColor: '#202020' }}
                      href={plan.buttonLink}
                      block
                    >
                      {plan.buttonText}
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          

          
          <Divider style={{ margin: '40px 0' }} />
        </Content>
      </Layout>

      {/* Modal de Ajuda */}
      <Modal
        title="Como Jogar?"
        visible={isHelpModalVisible}
        onOk={handleHelpModalOk}
        onCancel={handleHelpModalCancel}
        footer={[
          <Button key="ok" type="primary" onClick={handleHelpModalOk}>
            OK
          </Button>,
        ]}
        centered
        width={screens.md ? 600 : '90%'}
        bodyStyle={{ padding: '24px' }}
      >
        <Typography.Paragraph>
          {/* Conteúdo de ajuda aqui */}
          Bem-vindo ao Grand Garage! Aqui você pode comprar pacotes de dinheiro para expandir sua loja de carros,
          participar de eventos exclusivos, e muito mais. Escolha o plano que melhor se adapta às suas necessidades
          e comece a impulsionar seu negócio agora mesmo!
        </Typography.Paragraph>
      </Modal>
    </Layout>
  );
};

export default Shop;
