import React, { useState, useEffect } from 'react';
import { Card, Button, message, Row, Col, Grid, Layout, Carousel } from 'antd';
import { GoldOutlined } from '@ant-design/icons';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig'; // Certifique-se de que este caminho está correto
import { getAuth } from 'firebase/auth';

const { useBreakpoint } = Grid;
const { Content } = Layout;

const Isca = () => {
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [isBonusRedeemed, setIsBonusRedeemed] = useState(false);
  const screens = useBreakpoint();

  // URLs - altere conforme necessário
  const purchaseUrl1 = 'https://pay.kirvano.com/de45f2f8-11e5-490a-a942-ea90bdfd3153';
  const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl5.png?alt=media&token=2fd15d80-371a-43a4-93a6-3b73409f7d68';

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        const docRef = doc(firestore, 'players', uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setIsBonusRedeemed(data.isca);
        } else {
          console.error('Jogador não encontrado!');
        }
      } else {
        message.error('Usuário não autenticado.');
      }
    };

    fetchPlayerData();
  }, [auth.currentUser]);

  const handleRedeemBonus = async () => {
    setLoading(true);
    try {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        const docRef = doc(firestore, 'players', uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const playerData = docSnap.data();
          if (playerData.isca) {
            message.warning('Você já resgatou seu bônus!');
          } else {
            await updateDoc(docRef, {
              balance: playerData.balance + 2000000,
              isca: true,
            });
            setIsBonusRedeemed(true);
            message.success('R$2.000.000 resgatado com sucesso!');
          }
        } else {
          message.error('Jogador não encontrado!');
        }
      } else {
        message.error('Usuário não autenticado.');
      }
    } catch (error) {
      console.error('Falha ao resgatar o bônus:', error);
      message.error('Falha ao resgatar o bônus.');
    } finally {
      setLoading(false);
    }
  };

  // Estilos Inline
  const cardStyle = {
    width: '100%',
    maxWidth: '400px',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    padding: '20px',
    margin: 'auto',
  };

  const bonusButtonStyle = {
    backgroundColor: isBonusRedeemed ? '#a9a9a9' : '#28a745',
    border: 'none',
    color: '#fff',
    fontWeight: 'bold',
    animation: isBonusRedeemed ? 'none' : 'pulse 2s infinite',
    height: '50px',
    fontSize: '16px',
    borderRadius: '25px',
    cursor: isBonusRedeemed ? 'not-allowed' : 'pointer',
    width: '100%',
  };

  const carButtonStyle = {
    backgroundColor: '#28a745',
    border: 'none',
    color: '#fff',
    fontWeight: 'bold',
    animation: 'pulse 2s infinite',
    height: '50px',
    fontSize: '16px',
    borderRadius: '25px',
    marginTop: '20px',
    width: '100%',
    cursor: 'pointer',
  };

  // Configuração das imagens do carrossel
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Injetando Estilos para Animações e Responsividade */}
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
            }
            70% {
              transform: scale(1.05);
              box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
            }
            100% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
            }
          }

          .redeem-button:hover {
            background-color: ${isBonusRedeemed ? '#a9a9a9' : '#218838'} !important;
          }

          .purchase-button:hover {
            background-color: #218838 !important;
          }

          /* Personalizando indicadores do carrossel para preto */
          .ant-carousel .slick-dots li button {
            background-color: black; /* Cor do indicador inativo */
          }

          .ant-carousel .slick-dots li.slick-active button {
            background-color: black; /* Cor do indicador ativo */
          }

          @media (min-width: 768px) {
            .content-wrapper {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              padding: 40px;
              gap: 40px;
              flex-wrap: wrap;
              flex-direction: row;
            }

            .cards-row {
              display: flex;
              justify-content: center;
              gap: 40px;
              flex-wrap: wrap;
            }

            .logo {
              max-width: 200px;
              width: 100%;
              margin-bottom: 20px;
            }
          }

          @media (max-width: 767px) {
            .content-wrapper {
              padding: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 20px;
              height: auto;
            }

            .cards-row {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 20px;
            }

            .logo {
              max-width: 150px;
              width: 100%;
              margin-bottom: 20px;
            }
          }
        `}
      </style>

      <Content>
        <Row className="content-wrapper">
          {/* Row para os Cards */}
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row className="cards-row">
              {/* Card de Bônus de Boas-Vindas com Logo */}
              <Col xs={24} sm={24} md={12} lg={12}>
                <Card style={cardStyle} bordered={false}>
                  {/* Logo Inserido Dentro do Card de Bônus */}
                  <a href="https://luxurygears.app">
                    <img
                      src={logoUrl}
                      alt="Logo"
                      className="logo"
                      style={{ maxWidth: '150px', marginBottom: '20px' }}
                    />
                  </a>
                  <h2 style={{ color: '#28a745' }}>Obrigado por apoiar o jogo</h2>
                  <p style={{ fontSize: '18px', margin: '20px 0' }}>
                    Resgate agora seu bônus de <strong>R$2.000.000</strong>!
                  </p>
                  <Button
                    type="primary"
                    icon={<GoldOutlined />}
                    onClick={handleRedeemBonus}
                    loading={loading}
                    disabled={isBonusRedeemed}
                    style={bonusButtonStyle}
                    className="redeem-button"
                  >
                    {isBonusRedeemed ? 'Bônus Resgatado' : 'Resgatar Bônus'}
                  </Button>
                </Card>
              </Col>

              {/* Card de Oferta de Carro com Carrossel */}
              <Col xs={24} sm={24} md={12} lg={12}>
                <Card style={cardStyle} bordered={false}>
                  <Carousel {...carouselSettings}>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Brands%2FMclaren%2FW1%2Fcover_Screenshot_20241006_092355_YouTube.jpg?alt=media&token=b7a8a963-3720-4830-8e7f-cc4e92722b21"
                        alt="McLaren W1 2025 - Imagem 1"
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                          borderRadius: '10px 10px 0 0',
                          marginBottom: '20px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Brands%2FMclaren%2FW1%2FScreenshot_20241006_092157_YouTube.jpg?alt=media&token=93a74ba2-59ba-4511-a3f9-ef72747426a4"
                        alt="McLaren W1 2025 - Imagem 2"
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                          borderRadius: '10px 10px 0 0',
                          marginBottom: '20px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Brands%2FMclaren%2FW1%2FScreenshot_20241006_092321_YouTube.jpg?alt=media&token=bec66dc0-465c-4905-9d78-bf91412d60e9"
                        alt="McLaren W1 2025 - Imagem 3"
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                          borderRadius: '10px 10px 0 0',
                          marginBottom: '20px',
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Brands%2FMclaren%2FW1%2Finterior_1_Screenshot_20241006_092119_YouTube.jpg?alt=media&token=0ae03d0e-e897-442c-a613-4daf046656e4"
                        alt="McLaren W1 2025 - Imagem 3"
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                          borderRadius: '10px 10px 0 0',
                          marginBottom: '20px',
                        }}
                      />
                    </div>
                  </Carousel>
                  <h2 style={{ color: '#000' }}>McLaren W1 2025</h2>
                  <p style={{ fontSize: '18px', color: '#28a745', margin: '10px 0' }}>
                    <strong>Preço: R$9.27</strong>
                  </p>
                  <p style={{ fontSize: '16px', color: '#555' }}>
                    Limitada a 399 unidades na <strong>vida real e no jogo</strong>, ela se destaca
                    como o carro mais potente da história da McLaren, com impressionante de 1.258
                    cavalos.
                  </p>
                  <Button
                    type="primary"
                    onClick={() => window.open(purchaseUrl1, '_blank')}
                    style={carButtonStyle}
                    className="purchase-button"
                  >
                    Comprar Agora
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Isca;
