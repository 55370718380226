import React, { useState, useEffect } from 'react';
import {
  Layout,
  Menu,
  Card,
  Row,
  Col,
  Typography,
  Spin,
  Divider,
  Button,
  Modal,
  Alert,
  Drawer,
  Grid,
  Carousel,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import applySubscriptionBenefits from './applySubscriptionBenefits';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import {
  AppstoreOutlined,
  ShoppingOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  BankOutlined,
  CompassOutlined,
  NotificationOutlined,
  FundOutlined,
  StarOutlined,
  DollarOutlined,
  CrownOutlined,
  ShoppingFilled,
  GoldOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import AchievementsScreen from './AchievementsScreen';
import OffersSection from './OffersSection';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const Dashboard = () => {
  const [metrics, setMetrics] = useState({});
  const [balance, setBalance] = useState(0);
  const [gold, setGold] = useState(0); // Estado para o saldo em ouro
  const [loading, setLoading] = useState(true);
  const [playerData, setPlayerData] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [isTutorialModalVisible, setIsTutorialModalVisible] = useState(false); // Novo estado para o modal de tutorial
  const [error, setError] = useState(null);
  const [historyData, setHistoryData] = useState([]); // Adicionada a definição de setHistoryData
  const auth = getAuth();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchPlayerData(user.uid);
    } else {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, navigate]);

  useEffect(() => {
    const fetchAndApplyBenefits = async () => {
      const user = auth.currentUser;
      if (user) {
        console.log(`Tentando aplicar benefícios para playerId: ${user.uid}`);
        await applySubscriptionBenefits(user.uid);
        fetchPlayerData(user.uid);
      } else {
        console.log('Nenhum usuário autenticado encontrado.');
      }
    };

    fetchAndApplyBenefits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const showHelpModal = () => {
    setIsHelpModalVisible(true);
  };

  const handleHelpModalOk = () => {
    setIsHelpModalVisible(false);
  };

  const handleHelpModalCancel = () => {
    setIsHelpModalVisible(false);
  };

  const showTutorialModal = () => {
    setIsTutorialModalVisible(true);
  };

  const handleTutorialModalOk = () => {
    setIsTutorialModalVisible(false);
  };

  const handleTutorialModalCancel = () => {
    setIsTutorialModalVisible(false);
  };

  const fetchPlayerData = async (userId) => {
    try {
      const playerDocRef = doc(firestore, 'players', userId);
      const showroomCollectionRef = collection(playerDocRef, 'showroom');

      const [playerDocSnap, showroomSnapshot] = await Promise.all([
        getDoc(playerDocRef),
        getDocs(showroomCollectionRef),
      ]);

      if (playerDocSnap.exists()) {
        const playerData = playerDocSnap.data();
        setBalance(Number(playerData.balance) || 0);
        setGold(Number(playerData.gold) || 0); // Atualiza o ouro
        setPlayerData(playerData);
        const metricsData = playerData.metrics || {};

        let carsInShowroom = 0;
        let totalShowroomValue = 0;

        showroomSnapshot.forEach((doc) => {
          carsInShowroom += 1;
          totalShowroomValue += doc.data().valor || 0;
        });

        setMetrics({
          ...metricsData,
          carnumber: carsInShowroom,
          showroomValue: totalShowroomValue,
        });
      } else {
        console.log('Documento do jogador não encontrado.');
        setError('Dados do jogador não encontrados.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
      setError('Erro ao buscar dados do jogador.');
    } finally {
      setLoading(false);
    }
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Spin size="large" tip="Carregando..." />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Alert message="Erro" description={error} type="error" showIcon />
      </div>
    );
  }

  const getLogoUrl = () => {
    if (!playerData || playerData.sub === undefined || playerData.sub === null) {
      return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl6.png?alt=media&token=730338a6-0f61-4345-bca3-b03bd4aab180';
    }

    switch (playerData.sub) {
      case 1:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2FLogoVip2.png?alt=media&token=fee8d840-f927-4987-af10-3b58ef00e87c';
      case 2:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2FBillionLogo.png?alt=media&token=54c16bf7-b006-4cba-a961-746370fd7dee';
      default:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl6.png?alt=media&token=730338a6-0f61-4345-bca3-b03bd4aab180';
    }
  };

  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '26px' }} />} title="Dashboard">
        <Link to="/dashboard"></Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<CarOutlined style={{ fontSize: '26px' }} />} title="Showroom">
        <Link to="/showroom"></Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<ShopOutlined style={{ fontSize: '26px' }} />} title="Mercado">
        <Link to="/market"></Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<BankOutlined style={{ fontSize: '26px' }} />} title="Banco">
        <Link to="/bank"></Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '26px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal"></Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '26px' }} />} title="Ranking">
        <Link to="/ranking"></Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<ShoppingFilled style={{ fontSize: '26px' }} />} title="Loja">
        <Link to="/shop"></Link>
      </Menu.Item>
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
        }}
      >
        {/* Seção Esquerda: Logo e Botão de Tutorial */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={getLogoUrl()} alt="Logo" style={{ height: '40px', marginRight: '20px' }} />
          {/* Botão de Tutorial ao lado da logo */}
          <Button
  type="text"
  icon={<QuestionCircleOutlined style={{ fontSize: '24px', color: '#fff' }} />}
  onClick={() => navigate('/tutorial')} // Navega para a tela Tutorial
  title="Tutorial"
  style={{ color: '#fff', marginLeft: '16px' }} // Estilo para o texto
>
  Tutorial
</Button>
        </div>

        {/* Seção Direita: Menu */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {screens.md ? (
            <Menu theme="dark" mode="horizontal" style={{ background: '#080808' }}>
              {renderMenuItems()}
            </Menu>
          ) : (
            <Button
              type="text"
              icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
              onClick={showDrawer}
            />
          )}
        </div>
      </Header>

      {/* Drawer para Navegação Mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical" onClick={closeDrawer}>
          <Menu.Item key="1" icon={<AppstoreOutlined />} title="Dashboard">
            <Link to="/dashboard">Painel</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<CarOutlined />} title="Showroom">
            <Link to="/showroom">Showroom</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ShopOutlined />} title="Mercado">
            <Link to="/market">Mercado</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<CrownOutlined />} title="Ranking">
            <Link to="/ranking">Ranking</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<UserOutlined />} title="Coleção Pessoal">
            <Link to="/colecaopessoal">Coleção Pessoal</Link>
          </Menu.Item>
          <Menu.Item key="6s" icon={<ShoppingOutlined />} title="Loja">
            <Link to="/shop">Loja do Jogo</Link>
          </Menu.Item>
          {/* Outros itens do menu */}
        </Menu>
      </Drawer>

      {/* Modal de Ajuda */}
      <Modal
        title="Aprenda como jogar em 1 minuto"
        visible={isHelpModalVisible}
        onOk={handleHelpModalOk}
        onCancel={handleHelpModalCancel}
        footer={[
          <Button
            style={{ backgroundColor: '#000', borderColor: '#000' }}
            key="fechar"
            type="primary"
            onClick={handleHelpModalCancel}
          >
            Fechar
          </Button>,
        ]}
        centered
        width={800}
        bodyStyle={{ padding: '0', borderRadius: '8px' }}
        destroyOnClose
      >
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            paddingTop: '25px',
            height: '0',
          }}
        >
          <iframe
            title="Tutorial de Uso"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '8px',
            }}
            src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Media%2Fmkt%2Finshot.mp4?alt=media&token=529d7dc4-bcfe-4e6a-8bde-4d4530a9e5da"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>

      {/* Modal de Tutorial */}
      <Modal
        title="Tutorial do Jogo"
        visible={isTutorialModalVisible}
        onOk={handleTutorialModalOk}
        onCancel={handleTutorialModalCancel}
        footer={[
          <Button
            style={{ backgroundColor: '#000', borderColor: '#000' }}
            key="fechar-tutorial"
            type="primary"
            onClick={handleTutorialModalCancel}
          >
            Fechar
          </Button>,
        ]}

        centered
        width={800}
        bodyStyle={{ padding: '0', borderRadius: '8px' }}
        destroyOnClose
      >
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '0',
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/VIDEO_ID" // Substitua VIDEO_ID pelo ID do vídeo do YouTube
            title="Tutorial Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '8px',
            }}
          ></iframe>
        </div>
      </Modal>

      <Content style={{ margin: '0', backgroundColor: '#f0f2f5' }}>
        {/* Seção de Perfil e Métricas */}
        <div
          style={{
            background: '#080808',
            padding: '34px',
            borderBottom: '1px solid #1f1f1f',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          {/* Linha para Perfil, Saldo e Ouro */}
          <Row
            align="middle"
            gutter={[8, 8]}
            justify="right"
            style={{ width: '80%' }}
          >
            {/* Perfil */}
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/perfil')} // Adicionado para navegação ao clicar no perfil
              >
                <img
                  src={
                    playerData?.profileImage ||
                    'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Media%2FDALL%C2%B7E%202024-10-10%2010.46.43%20-%20A%20realistic%203D%20diorama%20of%20a%20small%20car%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20dealership%20has%20a%20modern%20design%20w.webp?alt=media&token=81b7e371-64a1-487d-ac0e-1071385e56e8'
                  }
                  alt="Perfil"
                  style={{
                    borderRadius: '4px',
                    width: '160px',
                    height: '160px',
                    objectFit: 'cover',
                    border: '2px solid #ffffff',
                    marginRight: '16px',
                    marginBottom: '25px',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Title level={4} style={{ color: '#ffffff', marginBottom: '8px' }}>
                    {playerData?.name || 'Minha Loja'}
                  </Title>
                  <Text style={{ color: '#b3b3b3', fontSize: '18px' }}>
                    Nvl: {playerData?.level || '1'}
                  </Text>
                </div>
              </div>
            </Col>

            {/* Saldo em Reais */}
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 20px',
                  background: '#202020',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  transition: 'background 0.3s',
                  marginBottom: screens.xs ? '16px' : '0', // Espaçamento no mobile
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Evita que o clique na div redirecione para /colecaopessoal
                  navigate('/extrato');
                }}
                onMouseEnter={(e) => (e.currentTarget.style.background = '#333333')}
                onMouseLeave={(e) => (e.currentTarget.style.background = '#202020')}
              >
                <DollarOutlined style={{ color: '#fff', marginRight: '8px', fontSize: '20px' }} />
                <div>
                  <Text style={{ color: '#ffffff', fontSize: '16px' }}></Text>
                  <Text strong style={{ color: '#ffffff', fontSize: '20px' }}>
                    R${balance.toLocaleString('pt-BR')}
                  </Text>
                </div>
              </div>
            </Col>

            {/* Saldo em Ouro 
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 20px',
                  background: '#202020',
                  borderRadius: '8px',
                }}
              >
                <GoldOutlined style={{ color: '#FFD700', marginRight: '8px', fontSize: '20px' }} />
                <div>
                  <Text style={{ color: '#ffffff', fontSize: '16px' }}></Text>
                  <Text strong style={{ color: '#ffffff', fontSize: '20px' }}>
                    {gold.toLocaleString('pt-BR')}
                  </Text>
                </div>
              </div>
            </Col>*/}
          </Row>

          {/* Seção de Métricas */}
          <Row
            gutter={[16, 16]}
            justify="start"
            style={{ marginTop: '24px', width: '100%' }}
          >
            {metrics.totalSales !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Vendidos</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    {metrics.totalSales}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.totalRevenue !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Receita</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    R${Number(metrics.totalRevenue || 0).toLocaleString('pt-BR')}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.carrosComprados !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Carros Comprados</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    {Number(metrics.carrosComprados || 0).toLocaleString('pt-BR')}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.totalProfit !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Lucro Total</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    R${Number(metrics.totalProfit || 0).toLocaleString('pt-BR')}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.showroomValue !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Valor Showroom</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    R${Number(metrics.showroomValue || 0).toLocaleString('pt-BR')}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.carnumber !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Carros na Loja</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    {metrics.carnumber}
                  </Text>
                </Card>
              </Col>
            )}
          </Row>
        </div>

        {/* Conteúdo Principal */}
        <div
          style={{
            padding: '24px',
            background: '#f0f2f5',
            minHeight: '360px',
          }}
        >
          <Divider style={{ margin: '24px 0' }} />

          {/* Seção de Conquistas */}
          <OffersSection />
          <AchievementsScreen />
        </div>
      </Content>
    </Layout>
  );
};

export default Dashboard;
